<template>
  <v-card 
    class="card qr-code"
    dark
  >
    <qrcode-stream 
      @decode="onDecode" 
      @init="onInit"
      :track="false"
    >
      <!-- <v-alert
        v-model="hint"
        close-text="Close Alert"
        border="left"
        dense
        dark
        transition="scale-transition"
        class="ma-4"
      >
        <span class="caption">Aproxime a câmera do código do Buzzer</span>
      </v-alert> -->
    </qrcode-stream>
  </v-card>
</template>

<style>

  .qr-code {
    border-radius: .5rem;
    overflow: hidden;
  }

</style>

<script>
  // Icons
  import {
    mdiClose
  } from '@mdi/js'

  // Utilities
  import {
    sync,
  } from 'vuex-pathify'

  import { QrcodeStream } from 'vue-qrcode-reader'


  export default {
    name: 'QrCodeReader',

    components: {
      QrcodeStream,
    },

    data: () => ({
      icons: {
        mdiClose
      },
      hint: true,
      code: null,
      error: '',
    }),

    computed: {
      
    },

    methods: {
      toggleView: function (b){
        this.toggle = b;
        console.log(b);
      },

      async onInit (promise) {
        try {
          await promise
        } catch (error) {
          if (error.name === 'NotAllowedError') {
            this.error = "ERROR: you need to grant camera access permisson"
          } else if (error.name === 'NotFoundError') {
            this.error = "ERROR: no camera on this device"
          } else if (error.name === 'NotSupportedError') {
            this.error = "ERROR: secure context required (HTTPS, localhost)"
          } else if (error.name === 'NotReadableError') {
            this.error = "ERROR: is the camera already in use?"
          } else if (error.name === 'OverconstrainedError') {
            this.error = "ERROR: installed cameras are not suitable"
          } else if (error.name === 'StreamApiNotSupportedError') {
            this.error = "ERROR: Stream API is not supported in this browser"
          }
          this.$emit('response', {
            type: 'error',
            value: this.error
          });
        }
      },

      onDecode (result) {
        this.code = result;
        this.$emit('response', {
          type: 'success',
          value: this.code
        });

        console.log(this.code);
      },

    },
  }
</script>
